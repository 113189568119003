// @ts-nocheck
/* eslint-disable */

"use client";

import {
  Button,
  Column,
  Heading,
  Icon,
  IconTitle,
  ImageWrapper,
  RichText,
  Row,
  Tabs,
} from "tempdev-ds";
import React, { Fragment } from "react";

import DisplayImage from "@/components/common/DisplayImage/DisplayImage";
import { IArchiveGrid } from "@/lib/wordpress/_types/archiveGrid";
import displayBlock from "@/lib/wordpress/blocks/displayBlock";
import { styled } from "styled-components";
import { useGlobalContext } from "@/lib/globalContext";

const StyledGrid = styled.div`
  display: block;
  margin: 0 -1rem;
`;
const StyledContent = styled.div`
  display: block;

  & a.d-block {
    display: table;
    color: ${(props) => (props.color ? props.color : "inherit")};
    margin-left: 2rem;
  }
`;

export default function BlockSolutionsTabs({ attrs }: IArchiveGrid) {
  const { grid, className } = attrs || {};
  const state = useGlobalContext();

  const gridCategories = grid?.categories?.filter(x => x.name === "products-category")[0]?.items;

  if (grid && gridCategories) {
    return (
      <div>
        {!attrs.hideNav && (
          <div
            className="d-flex flex-flow-column flex-flow-md-row align-items-center justify-content-center mb-6"
            style={{ gap: "16px" }}
          >
            {gridCategories.map((cat, i) => {
              return (
                <Fragment key={`solutions-cat-${i}`}>
                  <IconTitle
                    imageElement={
                      <DisplayImage
                        url={cat?.acf?.image as string}
                        alt={cat?.name ? (cat?.name as string) : ""}
                        width={48}
                        height={48}
                      />
                    }
                    className={"d-none d-md-flex"}
                    style={{ margin: 0 }}
                    onClick={() => {
                      state?.lenis.scrollTo(`#${cat.slug}`, {
                        offset: -document
                          .querySelector("#masthead")
                          .offsetHeight,
                      });
                    }}
                  >
                    <Heading size="texts" tag="h4">
                      {cat.name}
                    </Heading>
                  </IconTitle>
                  <IconTitle
                    imageElement={
                      <DisplayImage
                        url={cat?.acf?.image_featured as string}
                        alt={cat?.name ? (cat?.name as string) : ""}
                        width={24}
                        height={24}
                      />
                    }
                    className={"d-flex d-md-none radius-md w-100 p-4"}
                    style={{
                      backgroundColor: cat?.acf?.color_soft,
                      border: `1px solid ${cat?.acf?.color_strong}`,
                      margin: 0,
                    }}
                    onClick={() => {
                      state?.lenis.scrollTo(`#${cat.slug}`, {
                        offset: -document
                          .querySelector("#masthead")
                          .offsetHeight,
                      });
                    }}
                  >
                    <Heading size="h6" tag="h4">
                      {cat.name}
                    </Heading>
                    <Icon
                      icon={"icon-arrow-link"}
                      className="m-auto me-0"
                      style={{
                        color: cat?.acf?.color_strong,
                      }}
                    ></Icon>
                  </IconTitle>
                </Fragment>
              );
            })}
          </div>
        )}
        <StyledGrid>
          {gridCategories.map((cat, i) => {
            const newPosts = grid.items;
            const newData = [];
            newPosts &&
              newPosts.length > 0 &&
              newPosts
                .filter(
                  (x: Record<string, unknown>) => x.categories?.filter(x => x.name === "products-category")[0]?.items[0]?.slug === cat.slug
                )
                .map((block: Record<string, unknown>) => {
                  const result = {
                    id: block.id,
                    label: block.title,
                    color_soft: cat.acf?.color_soft,
                    color_medium: cat.acf?.color_medium,
                    color_strong: cat.acf?.color_strong,
                    content: (
                      <StyledContent color={cat.acf?.color_strong}>
                        {typeof block?.excerpt === "string" ? block?.excerpt : (
                          <>
                            {block?.excerpt && (block?.excerpt as Record<string, unknown>[])?.length > 0 && (block?.excerpt as Record<string, unknown>[])?.map(
                              (item: object, index: number) => {
                                return displayBlock(item as BlocksProps, index);
                              }
                            )}
                          </>
                        )}
                      </StyledContent>
                    ),
                  };
                  newData.push(result);
                });

            return (
              <>
                {newData && newData.length > 0 && (
                  <div
                    key={`tab-${cat.slug}`}
                    id={cat.slug}
                    className="radius-md radius-md-lg p-4 p-md-6 mb-6 mb-5"
                    style={{ backgroundColor: cat?.acf?.color_soft }}
                  >
                    <div className="d-flex align-items-center justify-content-center mb-6">
                      <DisplayImage
                        url={cat?.acf?.image_featured as string}
                        alt={cat?.name ? (cat?.name as string) : ""}
                        width={76}
                        height={76}
                      />
                      <Heading tag={"h3"} size={"h2"} className="ps-4">
                        {cat.name}
                      </Heading>
                    </div>
                    <Tabs menu={newData} className={className || ""}></Tabs>
                  </div>
                )}
              </>
            );
          })}
        </StyledGrid>
      </div>
    );
  } else {
    return <></>;
  }
}
