"use client";

import { Spacer } from "tempdev-ds";

export type IBlockSpacer = {
  attrs: {
    anchor?: string;
    customHeight?: string;
    className?: string;
  };
};

export default function BlockSpacer({ attrs }: IBlockSpacer) {
  const { anchor, customHeight = "8rem", className } = attrs || {};
  return <Spacer customHeight={customHeight} id={anchor || undefined} className={className} />;
}
