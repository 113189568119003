// @ts-nocheck
/* eslint-disable */

"use client";

import {
  BlogCard,
  Button,
  CareersCard,
  Column,
  EmptyState,
  Flex,
  FormField,
  Heading,
  Icon,
  Loader,
  Row,
  TeamCard,
} from "tempdev-ds";
import { Form, Formik } from "formik";
import { IArchiveGrid, IFilters } from "@/lib/wordpress/_types/archiveGrid";
import React, {
  ChangeEvent,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from "react";

import DisplayImage from "@/components/common/DisplayImage/DisplayImage";
import Link from "next/link";
import { WpClientRequest } from "@/lib/wordpress/wpClientRequest";
import { WpRequest } from "@/lib/wordpress/wpRequest";
import { formatDate } from "@/lib/helpers/dateFormate";
import { useGlobalContext } from "@/lib/globalContext";

export default function BlockArchiveGrid({ attrs }: IArchiveGrid) {
  const {
    title,
    variant,
    post_type,
    component,
    per_line = 4,
    per_page,
    showFilters,
    anchor,
    grid,
  } = attrs || {};
  const colSize = 12 / per_line;
  const [loading, setLoading] = useState<boolean | null>(false);
  const [filters, setFilters] = useState<IFilters | null>();
  const [filteredPosts, setFilteredPosts] = useState<
    IPost[] | undefined
  >(grid?.items);
  const state = useGlobalContext();
  const { lang } = state;

  let activeFilters: string[] = [];
  switch (component) {
    case "BlogCard":
      activeFilters = ["search", "category", "tags"];
      break;
    case "CareersCard":
      activeFilters = [];
      break;
    case "TeamCard":
      activeFilters = ["search", "category"];
      break;
  }

  useEffect(() => {
    if (grid && !filters) {
      setFilteredPosts(grid.items);
    }

    if (post_type && filters) {
      setLoading(true);
      const filteredItems2 = WpClientRequest({
        post_type,
        variables: {
          ...filters,
          limit: per_page,
          lang,
        },
      }).then((res) => {
        setFilteredPosts(res.items);
        setLoading(false);
      });
    }
  }, [filters, post_type, lang, grid]);

  return (
    <Row fluid={true}>
      <Column>
        <Flex align="center">
          {title && (
            <div className="flex-shrink-1 mb-3">
              <Heading tag="span" size="h4" className="fw-semibold">
                {title}
              </Heading>
            </div>
          )}
          {showFilters && activeFilters.length > 0 && (
            <div className="flex-grow-1 mb-3">
              <Formik
                initialValues={{}}
                onSubmit={async (values) => {
                  setFilters(values as IFilters);
                }}
              >
                {({ setFieldValue, values }) => {
                  return (
                    <Form>
                      <Flex align="center" justify="end" style={{ gap: "8px" }}>
                        {activeFilters.indexOf("search") >= 0 && (
                          <FormField
                            leftIcon={<Icon icon={"icon-search"} />}
                            placeholder={state?.dict?.search?.search}
                            name="search"
                            type="search"
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setFieldValue("search", e.target.value)
                            }
                          ></FormField>
                        )}
                        {activeFilters.indexOf("category") >= 0 && (
                          <FormField
                            name="category"
                            as="select"
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setFieldValue("category", e.target.value)
                            }
                          >
                            <option value={""}>
                              {state?.dict?.common?.chooseOption}
                            </option>
                            {grid &&
                              grid.categories &&
                              grid.categories.map((category, i) => {
                                return (
                                  <option
                                    key={`${category.name}-i`}
                                    value={category.id as string}
                                  >
                                    {category.name as string}
                                  </option>
                                );
                              })}
                          </FormField>
                        )}
                        {activeFilters.indexOf("date") >= 0 && (
                          <FormField
                            name="date"
                            type="date"
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setFieldValue("date", e.target.value)
                            }
                          ></FormField>
                        )}
                        <Button
                          variant={"icon"}
                          leftIcon={<Icon icon="icon-right-chevron" />}
                          as="button"
                          type="submit"
                        />
                      </Flex>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          )}
        </Flex>
      </Column>
      {loading ? (
        <Column className="text-center pt-5 pb-5">
          <Loader label={state?.dict?.common?.loading}></Loader>
        </Column>
      ) : (
        <Suspense
          fallback={<Loader label={state?.dict?.common?.loading}></Loader>}
        >
          {filteredPosts && filteredPosts.length !== 0 ? (
            filteredPosts.map((post, i: number) => {
              if (!post) return false;

              const category =
                (post?.categories as Record<string, unknown | string>) || [];
              const categoryName =
                category && category[0] ? (category[0].name as string) : "";
              const imageElement = (
                <DisplayImage
                  url={post?.image as string}
                  alt={post?.title ? (post?.title as string) : ""}
                  fill={true}
                />
              );

              switch (component) {
                case "BlogCard":
                  const author = post?.author
                    ? {
                        ...post?.author,
                        imageElement: (
                          <DisplayImage
                            url={post?.author?.img as string}
                            alt={
                              post?.author?.name
                                ? (post?.author?.name as string)
                                : ""
                            }
                            fill={true}
                          />
                        ),
                        linkElement: (
                          <Link
                            href={post?.author?.slug as string}
                            className="linker"
                          ></Link>
                        ),
                      }
                    : {};
                  return (
                    <Column key={`blogCard-${i}`} sm={12} md={colSize}>
                      <BlogCard
                        variant={variant}
                        date={formatDate(post?.date as string, 'DATE_SHORT')}
                        category={categoryName}
                        imageElement={imageElement}
                        title={post.title}
                        description={post.excerpt}
                        author={author}
                        linkElement={
                          <Link
                            href={post.slug as string}
                            className="linker"
                          ></Link>
                        }
                      />
                    </Column>
                  );
                  break;
                case "CareersCard":
                  return (
                    <Column key={`careersCard-${i}`} sm={12} md={colSize} className="mb-4">
                      <CareersCard
                        title={post.title}
                        description={post.excerpt}
                        linkElement={
                          <Link
                            href={post.slug as string}
                            className="linker"
                          ></Link>
                        }
                      />
                    </Column>
                  );
                  break;
                case "TeamCard":
                  return (
                    <Column key={`teamCard-${i}`} sm={12} md={colSize}>
                      <TeamCard
                        title={post.title}
                        description={post.excerpt}
                        category={categoryName}
                        imageElement={imageElement}
                      />
                    </Column>
                  );
              }
            })
          ) : (
            <Column className="pt-5 pb-5">
              <EmptyState label={state?.dict?.common?.noResults}></EmptyState>
            </Column>
          )}
        </Suspense>
      )}
    </Row>
  );
}
