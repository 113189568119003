// @ts-nocheck
/* eslint-disable */

"use client";

import {
  Accordion,
  AccordionItem,
  Button,
  Column,
  EmptyState,
  Flex,
  FormField,
  Icon,
  Loader,
  RichText,
  Row,
} from "tempdev-ds";
import { Form, Formik } from "formik";
import { IArchiveGrid, IFilters } from "@/lib/wordpress/_types/archiveGrid";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";

import { WpRequest } from "@/lib/wordpress/wpRequest";
import { useGlobalContext } from "@/lib/globalContext";

export default function BlockFaqsGrid({ attrs }: IArchiveGrid) {
  const { anchor, grid } = attrs || {};

  const [loading, setLoading] = useState<boolean | null>(false);
  const [filters, setFilters] = useState<IFilters | null>();
  const [filteredPosts, setFilteredPosts] = useState<
    Record<string, unknown>[] | null
  >(grid?.items);
  const state = useGlobalContext();
  const { lang } = state;

  useEffect(() => {
    if (grid && !filters) {
      setFilteredPosts(grid.items);
    }

    if (filters) {
      const filteredItems = WpRequest({
        post_type: "faqs",
        variables: {
          ...filters,
          lang,
        },
      }).then((res) => {
        setFilteredPosts(res.items);
        setLoading(false);
      });
    }
  }, [filters, lang, grid]);

  return (
    <Row>
      <Column>
        <Formik
          initialValues={{}}
          onSubmit={async (values) => {
            setLoading(true);
            setFilters(values as IFilters);
          }}
        >
          {({ setFieldValue, values }) => {
            return (
              <Form>
                <Flex align="center" justify="end" style={{ gap: "8px" }}>
                  <FormField
                    leftIcon={<Icon icon={"icon-search"} />}
                    placeholder={state?.dict?.search?.search}
                    name="search"
                    type="search"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setFieldValue("search", e.target.value)
                    }
                  ></FormField>
                  <Button
                    variant={"icon"}
                    leftIcon={<Icon icon="icon-right-chevron" />}
                    as="button"
                    type="submit"
                  />
                </Flex>
              </Form>
            );
          }}
        </Formik>
      </Column>
      {loading ? (
        <Column className="text-center pt-5 pb-5">
          <Loader label={state?.dict?.common?.loading}></Loader>
        </Column>
      ) : (
        <Column>
          {filteredPosts && filteredPosts.length !== 0 ? (
            <Accordion className="mt-5">
              {filteredPosts.map((post: Record<string, unknown>, i: number) => {
                if (!post) return false;
                return (
                  <AccordionItem key={`faq-${i}`} title={post.title}>
                    <RichText tag="p">{post.excerpt}</RichText>
                  </AccordionItem>
                );
              })}
            </Accordion>
          ) : (
            <Column className="pt-5 pb-5">
              <EmptyState label={state?.dict?.common?.noResults}></EmptyState>
            </Column>
          )}
        </Column>
      )}
    </Row>
  );
}
