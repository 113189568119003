"use client";

import { CSSProperties } from "react";
import { Video } from "tempdev-ds";
import classNames from "classnames";

export type IBlockVideo = {
  attrs: {
    anchor?: string;
    src?: string;
    poster?: string;
    autoplay?: boolean;
    controls?: boolean;
    loop?: boolean;
    muted?: boolean;
    playsInline?: boolean;
    className?: string;
    style?: CSSProperties;
    animation?: string;
  };
};

export default function BlockVideo({ attrs }: IBlockVideo) {
  return (
    <Video
      {...attrs}
      className={classNames(
        attrs.className,
        attrs.animation && `elements_animated ${attrs.animation}`
      )}
    ></Video>
  );
}
