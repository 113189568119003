// @ts-nocheck
/* eslint-disable */

"use client";

import {
  Button,
  Heading,
  Icon,
  IconTitle,
  Modal,
  Separator,
  TeamCard
} from "tempdev-ds";
import React, { useEffect, useState } from "react";

import DisplayImage from "@/components/common/DisplayImage/DisplayImage";
import { IArchiveGrid } from "@/lib/wordpress/_types/archiveGrid";
import Masonry from "react-masonry-css";
import cn from "classnames";
import { removeScroll } from "@/lib/helpers/removeScroll";
import { styled } from "styled-components";
import { useGlobalContext } from "@/lib/globalContext";

const StyledGrid = styled.div`
  .masonry-grid {
    margin: 0 -0.5rem;

    .masonry-grid_column > div {
      padding: 0.5rem;
    }
  }
`;
const StyledTwinkie = styled.div`
  position: relative;
  height: 11rem;
  overflow: visible;
  border-radius: 20px;
  pointer-events: none;
  background-color: #85d4ea;

  & img {
    position: absolute;
    left: 0;
    bottom: 0;
    object-fit: contain;
    object-position: bottom;
  }
`;

export default function BlockTeamGrid({ attrs }: IArchiveGrid) {
  const { grid, className } = attrs || {};
  const [filtered, setFiltered] = useState(grid.items || []);

  const [selectedRole, setSelectedRole] = useState();
  const [selectedLocation, setSelectedLocation] = useState();

  const [isOpen, setIsOpen] = useState(-1);
  const state = useGlobalContext();

  useEffect(() => {
    removeScroll(isOpen >= 0, state);
  }, [isOpen]);

  if (!filtered) return <></>;

  // unique selects
  const uniqueLocations = [
    ...new Set(grid.items.flatMap((item) => item.acf?.location || [])),
  ];
  const uniqueRoles = [
    ...new Set(grid?.categories?.filter(x => x.name==="team-category")[0]?.items?.map((item) => item.name || [])),
  ];

  useEffect(() => {
    const filterData = (data, location = "All", role = "All") => {
      return data.filter((item) => {
        return (
          (location === "All" || item.acf?.location === location) &&
          (role === "All" || item?.categories?.filter(x => x.name==="team-category")[0]?.items?.map((item) => item.name || []).includes(role))
        );
      });
    };
    setFiltered(filterData(attrs.grid.items, selectedLocation, selectedRole));
  }, [selectedLocation, selectedRole]);

  return (
    <StyledGrid>
      <div className="mt-5 mb-10 d-flex justify-content-center" style={{gap: "16px"}}>
        <div className="custom-select">
          <span className="h6">{state?.dict?.postTypes?.team}</span>
          <select
            value={selectedRole}
            className="texts"
            onChange={(e) => {
              setSelectedRole(e.target.value);
            }}
          >
            <option value="All">{state?.dict?.common?.all}</option>
            {uniqueRoles.map((role, index) => (
              <option key={role} value={role}>
                {role}
              </option>
            ))}
          </select>
          <Icon icon={"icon-down"} />
        </div>
        <div className="custom-select">
          <span className="h6">{state?.dict?.custom?.states}</span>
          <select
            value={selectedLocation}
            className="texts"
            onChange={(e) => {
              setSelectedLocation(e.target.value);
            }}
          >
            <option value="All">{state?.dict?.common?.all}</option>
            {uniqueLocations.map((location, index) => (
              <option key={location} value={location}>
                {location}
              </option>
            ))}
          </select>
          <Icon icon={"icon-down"} />
        </div>

      </div>

      {filtered && (
        <Masonry
          breakpointCols={{
            default: 4,
            1100: 3,
            700: 2,
            500: 2,
          }}
          className={cn("d-flex masonry-grid", className)}
          columnClassName="masonry-grid_column"
        >
          <div>
            <StyledTwinkie>
              <DisplayImage
                url={"/assets/components/team-twinkie.png"}
                alt={"Twinkie"}
                width={534}
                height={473}
              />
            </StyledTwinkie>
          </div>
          {filtered?.map((post: Record<string, unknown>, i: number) => {
            return (
              <div key={`team-${i}`}>
                <TeamCard
                  variant={post?.image ? "image" : "message"}
                  color={post?.acf?.color as string}
                  imageElement={
                    <DisplayImage
                      url={post?.image as string}
                      alt={post?.title ? (post?.title as string) : ""}
                      fill={true}
                    />
                  }
                  title={
                    <>
                      {post?.title && (
                        <Heading
                          tag={"span"}
                          size={"h2"}
                          className="font-scribbles"
                        >
                          {post?.title}
                        </Heading>
                      )}
                    </>
                  }
                  onClick={() => {
                    post?.image && setIsOpen(i);
                  }}
                />
                {i === isOpen && (
                  <Modal
                    open={isOpen === i}
                    onClose={() => setIsOpen(-1)}
                    data-lenis-prevent
                  >
                    <div className="d-flex" style={{ margin: "-1.75rem" }}>
                      <div
                        className="d-none d-md-block flex-shrink-1 radius-md"
                        style={{
                          backgroundColor: post?.acf?.color,
                          maxWidth: "230px",
                        }}
                      >
                        <DisplayImage
                          url={post?.image}
                          alt={post?.title}
                          width={267}
                          height={350}
                        />
                      </div>
                      <div
                        className={`d-flex flex-grow-1 flex-row flex-wrap p-4 pb-0`}
                      >
                        <div className="w-100 align-self-center">
                          <Heading
                            tag={"span"}
                            size={"h4"}
                            className="fw-semibold"
                          >
                            {post?.title}
                          </Heading>
                          <div className="d-block">
                            {post?.acf?.role && (
                              <IconTitle
                                imageElement={
                                  <DisplayImage
                                    url={"/assets/components/team-metrics.svg"}
                                    alt={"Job position"}
                                    width={25}
                                    height={25}
                                  />
                                }
                                className="mt-3 me-4"
                              >
                                <Heading size="h6" tag="h3">
                                  {post?.acf?.role}
                                </Heading>
                              </IconTitle>
                            )}
                            {post?.acf?.location && (
                              <IconTitle
                                imageElement={
                                  <DisplayImage
                                    url={"/assets/components/team-location.svg"}
                                    alt={"Location"}
                                    width={25}
                                    height={25}
                                  />
                                }
                                className="mt-3"
                              >
                                <Heading size="h6" tag="h4">
                                  {post?.acf?.location}
                                </Heading>
                              </IconTitle>
                            )}
                          </div>
                        </div>
                        <div className="w-100 align-self-end pb-4">
                          <Separator
                            height={"1px"}
                            color={"#dcdfe5"}
                            className="mt-3 mb-3"
                          ></Separator>
                          {post?.acf?.linkedin && (
                            <IconTitle
                              imageElement={
                                <DisplayImage
                                  url={"/assets/components/team-social.svg"}
                                  alt={"Linkedin profile"}
                                  width={25}
                                  height={25}
                                />
                              }
                              className="mt-3 me-4"
                            >
                              <Heading size="captions" tag="h4">
                                <Button
                                  variant="link"
                                  href={post?.acf?.linkedin}
                                  target="_blank"
                                >Linkedin</Button>
                              </Heading>
                            </IconTitle>
                          )}
                          {post?.acf?.article && (
                            <IconTitle
                              imageElement={
                                <DisplayImage
                                  url={"/assets/components/team-about.svg"}
                                  alt={"About me"}
                                  width={25}
                                  height={25}
                                />
                              }
                              className="mt-3"
                            >
                              <Heading size="captions" tag="h4">
                                <Button
                                  variant="link"
                                  href={post?.acf?.article}
                                >
                                  Meet {post?.title}
                                </Button>
                              </Heading>
                            </IconTitle>
                          )}
                        </div>
                      </div>
                    </div>
                  </Modal>
                )}
              </div>
            );
          })}
        </Masonry>
      )}
    </StyledGrid>
  );
}
