// @ts-nocheck
/* eslint-disable */

"use client";

import {
  Button,
  Column,
  EmptyState,
  GlossaryCard,
  Loader,
  Row,
  ScrollingNav,
} from "tempdev-ds";
import React, { Suspense, useEffect, useState } from "react";

import DisplayImage from "@/components/common/DisplayImage/DisplayImage";
import { IArchiveGrid } from "@/lib/wordpress/_types/archiveGrid";
import { useGlobalContext } from "@/lib/globalContext";

export default function BlockGlossaryGrid({ attrs }: IArchiveGrid) {
  const [loading, setLoading] = useState<boolean | null>(false);
  const state = useGlobalContext();

  const { grid, className } = attrs || {};

  const newPosts = grid?.items?.sort((a, b) => {
    const aTitle = a.acf?.title ? a.acf?.title : a.title;
    const bTitle = b.acf?.title ? b.acf?.title : b.title;
    return aTitle.localeCompare(bTitle);
  });
  // const newPosts = posts;
  const alphabetArray: Record<string, any> = [];

  newPosts &&
    newPosts.map((option: Record<any, string>, key: number) => {
      const title = option.acf?.title ? option.acf?.title : option.title;
      const letter = option.acf?.letter ? option.acf?.letter : title.charAt(0);

      if (!alphabetArray[letter]) {
        alphabetArray[letter] = [];
      }

      alphabetArray[letter].push(option);
    });

  const words = Object.keys(alphabetArray).sort((a, b) => a.localeCompare(b));

  return (
    <div>
      <div className="bg-primary30 text-center pt-2 pb-2 mb-8">
        <Row>
          <Column className="text-center">
            <ScrollingNav>
              {words &&
                words.map((word, key) => {
                  return (
                    <div key={`letter-${key}`}>
                      <Button
                        variant={"link"}
                        className="h5 text-uppercase"
                        onClick={() => {
                          state?.lenis?.scrollTo(
                            `#letter-${word.toLowerCase()}`,
                            {
                              offset: -80,
                            }
                          );
                        }}
                      >
                        {word}
                      </Button>
                    </div>
                  );
                })}
            </ScrollingNav>
          </Column>
        </Row>
      </div>
      <Row>
        {loading ? (
          <Column className="text-center pt-5 pb-5">
            <Loader label={state?.dict?.common?.loading}></Loader>
          </Column>
        ) : (
          <Suspense
            fallback={<Loader label={state?.dict?.common?.loading}></Loader>}
          >
            {words && words.length !== 0 ? (
              words.map((word: Record<string, unknown>, i: number) => {
                return (
                  <Column
                    key={`word-${word}`}
                    id={`letter-${word.toLowerCase()}`}
                    md={6}
                    className="mb-4"
                  >
                    <GlossaryCard
                      letter={word}
                      className={"h-100"}
                      imageElement={
                        <DisplayImage
                          url="/assets/components/glossary-letter.png"
                          width={60}
                          height={60}
                        />
                      }
                    >
                      <ul>
                        {!!alphabetArray[word] &&
                          alphabetArray[word].map((item, index) => {
                            const title = item.acf?.title
                              ? item.acf?.title
                              : item.title;
                            return (
                              <li key={`glossary-${index}`} className="mt-3">
                                <Button
                                  variant={"link"}
                                  href={`${item.slug}`}
                                  className="color-white"
                                >
                                  {title}
                                </Button>
                              </li>
                            );
                          })}
                      </ul>
                    </GlossaryCard>
                  </Column>
                );
              })
            ) : (
              <Column className="pt-5 pb-5">
                <EmptyState label={state?.dict?.common?.noResults}></EmptyState>
              </Column>
            )}
          </Suspense>
        )}
      </Row>
    </div>
  );
}
