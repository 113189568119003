import WpFetch from "./utils/wpFetch";
import { cache } from "react";
import formatBlockData from "./blocks/formatBlockData";

export type WpRequestVariables = {
  slug?: string;
  lang?: string;
  limit?: number;
  category?: number;
  offset?: number;
  order?: string;
  orderBy?: string;
};

export const WpClientRequest = async ({
  post_type = "",
  variables = {},
  isContent = true,
  forceUpdate = false,
  liveOnly = false,
}: {
  post_type: string;
  variables?: WpRequestVariables;
  isContent?: boolean;
  forceUpdate?: boolean;
  liveOnly?: boolean;
}) => {
  const requestObject = JSON.stringify({
    post_type,
    variables,
    isContent,
    // ignore this terms
    // forceUpdate,
    // liveOnly,
  });

  const res = await fetch(
    `/api/findContent?${new URLSearchParams({
      filter: requestObject,
    }).toString()}`);

  return await res.json();
};
