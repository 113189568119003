"use client";

import { CSSProperties } from "react";
import { VideoEmbed } from "tempdev-ds";
import classNames from "classnames";

export type IBlockVideoEmbed = {
  attrs: {
    anchor?: string;
    embed?: string;
    poster?: string;
    autoplay?: boolean;
    controls?: boolean;
    loop?: boolean;
    muted?: boolean;
    playsInline?: boolean;
    className?: string;
    style?: CSSProperties;
    animation?: string;
  };
};

export default function BlockVideoEmbed({ attrs }: IBlockVideoEmbed) {
  return (
    <VideoEmbed
      {...attrs}
      className={classNames(
        attrs.className,
        attrs.animation && `elements_animated ${attrs.animation}`
      )}
    ></VideoEmbed>
  );
}
