export const dateToIso = (date: string | null): string | null => {
  if (!date || date === '') return null;
  return new Date(date).toISOString();
};
export const dateToCalendar = (date: string | null): string | null => {
  if (!date || date === '') return null;
  return date.split('T')[0];
};
/**
 * DATE_SHORT => 'ddds'
 */
enum DatePreset {
  /**
   * Return example : terça-feira, 9 de maio de 2023
   */
  DATE_HUGE = 'DATE_HUGE',
  /**
   * Return example : 14/10/1983
   */
  DATE_MED = 'DATE_MED',
  /**
   * Return example : 14/10/1983
   */
  DATE_SHORT = 'DATE_SHORT',
}
const DatePresets: Record<keyof typeof DatePreset, Intl.DateTimeFormatOptions> = {
  DATE_HUGE: {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
  DATE_MED: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
  DATE_SHORT: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
};
/**
 *
 * @param date
 * @param preset
 * @param locale
 * @returns
 */
export const formatDate = (date: string | Date, preset?: keyof typeof DatePreset, locale = 'en'): string => {
  if (!date || date === '') return '-';
  if (!preset) return new Date(date).toLocaleDateString(locale);
  const currentLocaleOptions = DatePresets[preset] as Intl.DateTimeFormatOptions;
  const formatter = new Intl.DateTimeFormat(locale, currentLocaleOptions);

  return formatter.format(new Date(date));
};


export const getCurrentDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  // if (dd < 10) dd = `0${dd}`;
  // if (mm < 10) mm = `0${mm}`;

  return yyyy + '-' + mm + '-' + dd;
}
