// @ts-nocheck
/* eslint-disable */

"use client";

import {
  Button,
  Column,
  Flex,
  Heading,
  Marquee,
  Modal,
  RichText,
  Row,
  TestimonialsCard,
} from "tempdev-ds";
import React, { useEffect, useState } from "react";

import { IArchiveGrid } from "@/lib/wordpress/_types/archiveGrid";
import Link from "next/link";
import { removeScroll } from "@/lib/helpers/removeScroll";
import { useGlobalContext } from "@/lib/globalContext";

export default function BlockTestimonialsGrid({ attrs }: IArchiveGrid) {
  const [isOpen, setIsOpen] = useState(-1);
  const { grid, className } = attrs || {};
  const state = useGlobalContext();

  useEffect(() => {
    removeScroll(isOpen >= 0, state);
  }, [isOpen]);

  if (!grid || !grid.items) return <></>;

  return (
    <Marquee>
      {grid?.items.map((post: Record<string, unknown>, i: number) => {
        return (
          <>
            <TestimonialsCard
              key={`testimonial-${i}`}
              color={post?.acf?.color}
              title={post?.title}
              description={post?.acf?.client}
              buttonElement={
                <Button outlined className="mt-5" onClick={() => setIsOpen(i)}>
                  {state?.dict?.custom?.allArticles}
                </Button>
              }
              className={className}
            />
            {i === isOpen && (
              <Modal
                title={post?.acf?.case_study}
                open={isOpen === i}
                onClose={() => setIsOpen(-1)}
                data-lenis-prevent={true}
              >
                {post?.acf?.client && (
                  <Row fluid={true}>
                    <Column md={3}>
                      <Heading
                        tag="span"
                        size="texts"
                        className={"fw-semibold mb-3"}
                      >
                        {state?.dict?.custom?.client}
                      </Heading>
                    </Column>
                    <Column md={9}>
                      <RichText tag="p" className={"mb-3"}>
                        {post?.acf?.client}
                      </RichText>
                    </Column>
                  </Row>
                )}
                {post?.acf?.challenge && (
                  <Row fluid={true}>
                    <Column md={4} lg={3} className="fw-semibold">
                      <Heading
                        tag="span"
                        size="texts"
                        className={"fw-semibold mb-3"}
                      >
                        {state?.dict?.custom?.challenge}
                      </Heading>
                    </Column>
                    <Column md={8} lg={9}>
                      <RichText tag="p" className={"mb-3"}>
                        {post?.acf?.challenge}
                      </RichText>
                    </Column>
                  </Row>
                )}
                {post?.acf?.products && (
                  <Row fluid={true}>
                    <Column md={3}>
                      <Heading
                        tag="span"
                        size="texts"
                        className={"fw-semibold mb-3"}
                      >
                        {state?.dict?.custom?.solutions}
                      </Heading>
                    </Column>
                    <Column md={9}>
                      <RichText tag="p" className={"mb-3"}>
                        {post?.acf?.products}
                      </RichText>
                    </Column>
                  </Row>
                )}
                <Flex justify="center">
                  <Link href={"/contact-us/"} legacyBehavior>
                    <Button
                      outlined
                      href={"/contact-us/"}
                      className="mt-4"
                      style={{ width: "fit-content" }}
                    >
                      {state?.dict?.custom?.contactMoreInfo}
                    </Button>
                  </Link>
                </Flex>
              </Modal>
            )}
          </>
        );
      })}
    </Marquee>
  );
}
