/**
 * Overrides the fetch to Wordpress, add here extra parameters for fetch data
 * @param {string} url - url to get data
 */

async function WpFetch(url: string, preview: boolean, tag: string = "wp_content") {
  const auth = 'Basic ' + btoa(process.env.WORDPRESS_APPLICATION_USERNAME + ":" + process.env.WORDPRESS_APPLICATION_PASSWORD)

  return fetch(url, {
    // 14400 sec = 4h
    next: { revalidate: 14400, tags: [tag] },
    // cache: preview ? 'no-store' : 'default'
    //credentials: 'user:passwd'
    headers: {
      'Authorization': auth
    },
  });
}

export default WpFetch;
