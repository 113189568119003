// @ts-nocheck
/* eslint-disable */

"use client";

import { Button, Icon } from "tempdev-ds";

import Link from "next/link";
import classNames from "classnames";
import { useGlobalContext } from "@/lib/globalContext";

export type IBlockButton = {
  attrs: {
    id?: string;
    variant?: string;
    text?: string;
    href?: {
      url?: string;
      newTab?: boolean;
    };
    icon?: string;
    iconLeft?: boolean;
    className?: string;
    style?: Record<string, unknown>;
    animation?: string;
  };
};

export default function BlockButton({ attrs }: IBlockButton) {
  const state = useGlobalContext();
  const {
    id,
    variant,
    text,
    href,
    icon,
    iconLeft,
    className,
    style,
    animation,
    outlined
  } = attrs || {};

  // No button href? Bail.
  const url =
    href?.url?.replace(
      process.env.NEXT_PUBLIC_WP_URL,
      process.env.NEXT_PUBLIC_FE_URL
    ) || "";
  const newTab = href?.newTab;

  const linkTarget = newTab ? "_blank" : "_parent";
  // if (!url) return <></>

  const anchorScroll = (e: any, url: string) => {
    if (url.indexOf("#") === 0) {
      e.preventDefault();
      state?.lenis.scrollTo(url);
    }
  };

  if (linkTarget === "_blank" || !url) {
    return (
      <Button
        id={id}
        variant={variant}
        href={url}
        target={linkTarget}
        rel={linkTarget ? "noreferrer noopener" : ""}
        aria-label={text}
        className={classNames(
          className,
          animation && `elements_animated ${animation}`
        )}
        style={style}
        outlined={outlined}
      >
        {text}
      </Button>
    );
  }

  return (
    <Link href={url} as={url} prefetch={false} legacyBehavior>
      <Button
        id={id}
        variant={variant}
        href={url}
        leftIcon={iconLeft && icon && <Icon icon={icon} />}
        rightIcon={!iconLeft && icon && <Icon icon={icon} />}
        className={classNames(
          className,
          animation && `elements_animated ${animation}`
        )}
        style={style}
        onClick={(e: any) => {
          anchorScroll(e, url);
        }}
        outlined={outlined}

      >
        {text}
      </Button>
    </Link>
  );
}
