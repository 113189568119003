// @ts-nocheck
/* eslint-disable */

"use client";

import { Icon, IconTitle } from "tempdev-ds";

import { BlocksProps } from "@/lib/wordpress/_types/blocksStyle";
import DisplayImage from "@/components/common/DisplayImage/DisplayImage";
import Link from "next/link";
import displayBlock from "@/lib/wordpress/blocks/displayBlock";
import getBlockStyles from "@/lib/wordpress/blocks/getBlockStyles";
import { useGlobalContext } from "@/lib/globalContext";

export type IBlockIconTitle = {
  attrs: {
    anchor?: string;
    label?: string;
    icon?: string;
    mediaUrl?: string;
    mediaSize?: string;
    align?: string;
    textColor?: string;
    href?: {
      url?: string;
      newTab?: boolean;
    };
    className?: string;
    style?: Record<string, unknown>;
  };
  innerBlocks: BlocksProps[];
};

export default function BlockIconTitle({
  attrs,
  innerBlocks,
}: IBlockIconTitle) {
  const state = useGlobalContext();

  const { icon, mediaUrl, mediaSize, align, textColor, href, className, style } = attrs || {};

  const elStyle = getBlockStyles({
    textColor,
    align,
    style,
  });

  let mediaSizes = mediaSize && mediaSize.indexOf('undefined') !== 0 ? mediaSize.split("x") : ["24", "24"]

  const url =
    href?.url?.replace(
      process.env.NEXT_PUBLIC_WP_URL,
      process.env.NEXT_PUBLIC_FE_URL
    ) || "";
  const newTab = href?.newTab;

  const linkTarget = newTab ? "_blank" : "_parent";

  const anchorScroll = (e: any, url: string) => {
    if (url.indexOf("#") === 0) {
      e.preventDefault();
      state?.lenis.scrollTo(url);
    }
  };

  const component = (
    <IconTitle
      icon={icon && <Icon icon={icon} />}
      imageElement={
        mediaUrl && (
          <DisplayImage
            url={mediaUrl as string}
            width={parseInt(mediaSizes[0])}
            height={parseInt(mediaSizes[1])}
          />
        )
      }
      className={className}
      style={elStyle}
    >
      {!!innerBlocks?.length &&
        innerBlocks?.map((block: BlocksProps, index: number) => {
          return displayBlock(block, index);
        })}
    </IconTitle>
  )

  if (!url) {
    return (
      component
    );
  } else {
    return (
      <Link
        href={url}
        as={url}
        prefetch={false}
        target={linkTarget}
        onClick={(e: any) => {
          anchorScroll(e, url);
        }}
      >
        {component}
      </Link>
    )
  }
}
