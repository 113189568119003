// @ts-nocheck
/* eslint-disable */
'use client'

import DisplayImage from '@/components/common/DisplayImage/DisplayImage'
import { LinkCard } from 'tempdev-ds'
import { useGlobalContext } from '@/lib/globalContext'

export type IBlockLinkCard = {
  attrs: {
    anchor?: string
    image?: {
      url?: string
      alt?: string
    }
    title?: string
    description?: string
    href?: {
      url?: string
      newTab?: boolean
    }
    className?: string
    style?: Record<string, unknown>
  }
}

export default function BlockLinkCard({ attrs }: IBlockLinkCard) {
  const { image, title, description, href, className, style } = attrs || {}
  const state = useGlobalContext()

  const url = href?.url
  const newTab = href?.newTab

  const linkTarget = newTab ? '_blank' : '_parent'

  return (
    <LinkCard
      title={title}
      description={description}
      imageElement={<DisplayImage alt={image?.alt ? image?.alt : ''} url={image?.url} fill={true} />}
      buttonLabel={state?.dict?.common?.knowMore}
      linkElement={<a href={url} target={linkTarget} className="linker"></a>}
      className={className}
      style={style}
    ></LinkCard>
  )
}
