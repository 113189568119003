// @ts-nocheck
/* eslint-disable */

import BlockAccordion from "@/components/blocks/miewtheme/BlockAccordion/BlockAccordion";
import BlockArchiveGrid from "@/components/blocks/miewtheme/BlockArchiveGrid/BlockArchiveGrid";
import BlockAuthorsGrid from "@/components/blocks/miewtheme/BlockAuthorsGrid/BlockAuthorsGrid";
import BlockButton from "@/components/blocks/core/BlockButton/BlockButton";
import BlockCarousel from "@/components/blocks/miewtheme/BlockCarousel/BlockCarousel";
import BlockColumns from "@/components/blocks/core/BlockColumns/BlockColumns";
import BlockCover from "@/components/blocks/core/BlockCover/BlockCover";
import BlockFaqsGrid from "@/components/blocks/miewtheme/BlockFaqsGrid/BlockFaqsGrid";
import BlockGlossaryGrid from "@/components/blocks/miewtheme/BlockGlossaryGrid/BlockGlossaryGrid";
import BlockGravityForm from "@/components/blocks/miewtheme/BlockGravityForm/BlockGravityForm";
import BlockGroup from "@/components/blocks/core/BlockGroup/BlockGroup";
import BlockHeading from "@/components/blocks/core/BlockHeading/BlockHeading";
import BlockIconTitle from "@/components/blocks/miewtheme/BlockIconTitle/BlockIconTitle";
import BlockImage from "@/components/blocks/core/BlockImage/BlockImage";
import BlockLinkCard from "@/components/blocks/miewtheme/BlockLinkCard/BlockLinkCard";
import BlockList from "@/components/blocks/core/BlockList/BlockList";
import BlockLogosGrid from "@/components/blocks/miewtheme/BlockLogosGrid/BlockLogosGrid";
import BlockMarquee from "@/components/blocks/miewtheme/BlockMarquee/BlockMarquee";
import BlockModal from "@/components/blocks/miewtheme/BlockModal/BlockModal";
import BlockParagraph from "@/components/blocks/core/BlockParagraph/BlockParagraph";
import BlockQuote from "@/components/blocks/core/BlockQuote/BlockQuote";
import BlockReusable from "@/components/blocks/core/BlockReusable/BlockReusable";
import BlockSeparator from "@/components/blocks/core/BlockSeparator/BlockSeparator";
import BlockServicesAcc from "@/components/blocks/miewtheme/BlockServicesAcc/BlockServicesAcc";
import BlockServicesTabs from "@/components/blocks/miewtheme/BlockServicesTabs/BlockServicesTabs";
import BlockSolutionsTabs from "@/components/blocks/miewtheme/BlockSolutionsTabs/BlockSolutionsTabs";
import BlockSpacer from "@/components/blocks/core/BlockSpacer/BlockSpacer";
import BlockTabs from "@/components/blocks/miewtheme/BlockTabs/BlockTabs";
import BlockTeamGrid from "@/components/blocks/miewtheme/BlockTeamGrid/BlockTeamGrid";
import BlockTestimonialsGrid from "@/components/blocks/miewtheme/BlockTestimonialsGrid/BlockTestimonialsGrid";
import BlockVideo from "@/components/blocks/core/BlockVideo/BlockVideo";
import BlockVideoEmbed from "@/components/blocks/core/BlockVideoEmbed/BlockVideoEmbed";
import { BlocksProps } from "../_types/blocksStyle";
import { IGravityForms } from "../forms/types";

export default function displayBlock(block: BlocksProps, index: number) {
  if (!block) return <></>
  const { attrs, blockName, innerBlocks } = block;

  // prettier-ignore
  switch (blockName) {
    case 'core/block': {
      return <BlockReusable innerBlocks={innerBlocks} key={index} />
    }

    case 'core/image': {
      attrs.fill = false
      attrs.className = (attrs?.className || '') + (attrs.mobileHidden ? ' wp-image d-none d-md-inline-block align-top w-100' : ' wp-image d-inline-block align-top w-100');
      attrs.maxWidth = attrs.fixedSize===true ? attrs.width : "";

      return (
        <BlockImage
          key={index}
          attrs={attrs}
        />
      )
    }

    case 'core/heading': {
      return <BlockHeading attrs={attrs} key={index} />
    }

    case 'core/paragraph': {
      return <BlockParagraph attrs={attrs} key={index} />
    }

    case 'miewtheme/button': {
      return <BlockButton attrs={attrs} key={index} />
    }

    case 'core/list': {
      return <BlockList attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'core/quote': {
      return <BlockQuote attrs={attrs} key={index} />
    }

    case 'core/video': {
      return <BlockVideo attrs={attrs} key={index} />
    }
    case 'core/embed': {
      attrs.embed = attrs.url;
      return <BlockVideoEmbed attrs={attrs} key={index} />
    }

    case 'core/columns': {
      return (
        <BlockColumns
          attrs={attrs}
          innerBlocks={innerBlocks}
          key={index}
        />
      )
    }

    case 'core/group': {
      return <BlockGroup attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'core/cover': {
      return <BlockCover attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'miewtheme/accordion': {
      return <BlockAccordion attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'miewtheme/carousel': {
      return <BlockCarousel attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'core/spacer':
    case 'miewtheme/spacer': {
      return <BlockSpacer attrs={attrs} key={index} />
    }

    case 'miewtheme/separator': {
      return <BlockSeparator attrs={attrs} key={index} />
    }

    case 'miewtheme/modal': {
      return <BlockModal attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'miewtheme/marquee': {
      return <BlockMarquee attrs={attrs} key={index} />
    }
    case 'miewtheme/tabs': {
      return <BlockTabs attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }


    case 'miewtheme/iconbox': {
      return <BlockIconBox attrs={attrs} key={index} />
    }

    case 'miewtheme/icontitle': {
      return <BlockIconTitle attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'miewtheme/linkcard': {
      return <BlockLinkCard attrs={attrs} key={index} />
    }

    case 'miewtheme/archive-grid': {
      return <BlockArchiveGrid attrs={attrs} key={index} />
    }
    case 'miewtheme/authors-grid': {
      return <BlockAuthorsGrid attrs={attrs} key={index} />
    }
    case 'miewtheme/testimonials-grid': {
      return <BlockTestimonialsGrid attrs={attrs} key={index} />
    }
    case 'miewtheme/team-grid': {
      return <BlockTeamGrid attrs={attrs} key={index} />
    }
    case 'miewtheme/logos-grid': {
      return <BlockLogosGrid attrs={attrs} key={index} />
    }
    case 'miewtheme/faqs-grid': {
      return <BlockFaqsGrid attrs={attrs} key={index} />
    }
    case 'miewtheme/glossary-grid': {
      return <BlockGlossaryGrid attrs={attrs} key={index} />
    }
    case 'miewtheme/products-grid': {
      return <BlockSolutionsTabs attrs={attrs} key={index} />
    }
    case 'miewtheme/services-grid': {
      return <BlockServicesTabs attrs={attrs} key={index} />
    }
    case 'miewtheme/services-acc': {
      return <BlockServicesAcc attrs={attrs} key={index} />
    }
    case 'gravityforms/form': {
      return <BlockGravityForm attrs={attrs as IGravityForms} key={index} />    }
    default: {
      if (process.env.NODE_ENV === 'development') {
        console.log('display block not found', blockName, index)
        return <pre key={index}>{JSON.stringify(block, null, 2)}</pre>
      } else {
        return <></>
      }
    }
  }
}
