// @ts-nocheck
/* eslint-disable */

"use client";

import React, { useEffect, useState } from "react";

import { Tabs } from "tempdev-ds";
import displayBlock from "@/lib/wordpress/blocks/displayBlock";

export type IBlockCover = {
  attrs: {
    className?: string;
    style?: Record<string, unknown>;
  };
  innerBlocks: BlocksProps[];
};
export default function BlockTabs({ attrs, innerBlocks }: IBlockCover) {
  const { className } = attrs || {};
  const menu =
    innerBlocks &&
    innerBlocks.length > 0 &&
    innerBlocks.map((block: Record<string, unknown>, i: number) => {
      return {
        id: i,
        label: block.attrs.title,
        color_soft: '#cbedf6',
        color_medium: '#cbedf6',
        color_strong: '#cbedf6',
        content: (
          <div>
            {!!block.innerBlocks?.length &&
              block.innerBlocks.map((block: BlocksProps, index: number) => {
                return displayBlock(block as BlocksProps, index);
              })}
          </div>
        ),
      };
    });

  return <Tabs menu={menu} className={className || ""}></Tabs>;
}
