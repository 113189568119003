// @ts-nocheck
/* eslint-disable */

"use client";

import { AuthorCard, Button, Column, Icon, Row } from "tempdev-ds";

import DisplayImage from "@/components/common/DisplayImage/DisplayImage";
import { IArchiveGrid } from "@/lib/wordpress/_types/archiveGrid";
import Link from "next/link";
import React from "react";
import { useGlobalContext } from "@/lib/globalContext";

export default function BlockAuthorsGrid({ attrs }: IArchiveGrid) {
  const { per_page, className } = attrs || {};
  const { grid } = attrs || [];
  const state = useGlobalContext();

  if (!grid) return <></>;

  return (
    <Row fluid={true}>
      {grid.map((post: Record<string, unknown>, i: number) => {
        return (
          <Column key={`blogAuthor-${i}`} md={12 / per_page}>
            <AuthorCard
              variant={"md"}
              name={post?.name}
              role={post?.acf?.role}
              imageElement={
                <DisplayImage
                  url={post?.acf?.profile_image as string}
                  alt={post?.name ? (post?.name as string) : ""}
                  fill={true}
                />
              }
              linkElement={
                <Link href={post.slug} legacyBehavior>
                  <Button
                    variant={"link"}
                    rightIcon={<Icon icon="icon-arrow-link"></Icon>}
                    href={post.slug}
                    className="mt-4"
                  >
                    {state?.dict?.custom?.allArticles}
                  </Button>
                </Link>
              }
              className={className}
            />
          </Column>
        );
      })}
    </Row>
  );
}
