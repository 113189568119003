import WpFetch from "./utils/wpFetch";
import formatBlockData from "./blocks/formatBlockData";

export type WpRequestVariables = {
  slug?: string;
  ids?: number[];
  lang?: string;
  limit?: number;
  search?: string; //used for search page
  category?: string | number;
  offset?: number;
  order?: string;
  orderby?: string;
  date?: string; //get posts for this date
  dateAfter?: boolean; //get posts after this date
  dateBefore?: boolean; //get posts before this date
  preview?: boolean
};

export const WpRequest = async ({
  post_type = "",
  variables = {},
  isContent = true,
  isForms = false,
  searchParams,
  tag = "wp_content",
}: {
  post_type: string;
  variables?: WpRequestVariables;
  isContent?: boolean;
  isForms?: boolean;
  searchParams?: { q?: string };
  tag?: string;
}) => {
  performance.mark('WpRequest:start');

  const endpoint = isContent ? `${process.env.NEXT_PUBLIC_WP_API}miew/content/?post_type=${post_type}&` : (isForms ? `${process.env.NEXT_PUBLIC_WP_API}${post_type}?` : `${process.env.NEXT_PUBLIC_WP_API}miew/${post_type}?`);

  variables.lang = variables?.lang ? variables?.lang : "en" //default

  for (let param in variables) { /* You can get copy by spreading {...query} */
    if ((variables[param as keyof WpRequestVariables]) === undefined /* In case of undefined assignment */
      || variables[param as keyof WpRequestVariables] === null
      || variables[param as keyof WpRequestVariables] === ""
    ) {
      delete variables[param as keyof WpRequestVariables];
    }
  }


  console.log(
    `${endpoint}${new URLSearchParams(
      variables as Record<string, string>
    ).toString()}`
  );
  if (!endpoint) {
    console.log(
      `${endpoint}${new URLSearchParams(
        variables as Record<string, string>
      ).toString()}`
    );
    console.error("Failed to fetch data, no endpoint defined");
    // throw new Error('Failed to fetch data');
    return {};
  }

  const data = await WpFetch(
    `${endpoint}&${new URLSearchParams(
      variables as Record<string, string>
    ).toString()}`,
    variables.preview || false,
    tag
  );


  if (!data.ok) {
    // This will activate the closest `error.js` Error Boundary
    console.log(
      `${endpoint}${new URLSearchParams(
        variables as Record<string, string>
      ).toString()}`
    );

    console.error("Failed to fetch data");
    // throw new Error("Failed to fetch data");
    return {};
  }

  const result = await data.json();

  if (result.blocks) {
    result.blocks = await formatBlockData(
      result.blocks,
      (variables as Record<string, string>).lang,
      searchParams ? searchParams : {}
    );
  } else {
    delete result.block;
  }

  performance.mark('WpRequest:end');
  performance.measure('WpRequest', 'WpRequest:start', 'WpRequest:end');


  return result;
};
