'use client'

import { BlocksProps } from '@/lib/wordpress/_types/blocksStyle'
import displayBlock from '@/lib/wordpress/blocks/displayBlock';

export default function BlockReusable({ innerBlocks }: { innerBlocks: BlocksProps[]}) {
  return (
    <div>
      {!!innerBlocks?.length &&
        innerBlocks.map((block: BlocksProps, index: number) => {
          return displayBlock(block, index)
        })}
    </div>
  )
}
