'use client'

import { Flex, LogosCard } from 'tempdev-ds'

import { IArchiveGrid } from '@/lib/wordpress/_types/archiveGrid'
import Image from 'next/image'
import React from 'react'

export default function BlockLogosGrid({ attrs }: IArchiveGrid) {
  const { grid, className } = attrs || {}

  return (
    <>
      {grid?.items && (
        <Flex justify="center" className={className || ''}>
          {grid?.items.map((post: Record<string, unknown>, i: number) => {
            return (
              <LogosCard
                key={`logo-${i}`}
                title={post?.title as string}
                imageElement={
                  post?.image !== '' ? (
                    <Image src={post?.image as string} alt={post?.title as string} fill={true} style={{ objectFit: 'contain' }} />
                  ) : (
                    <></>
                  )
                }
              />
            )
          })}
        </Flex>
      )}
    </>
  )
}
