// @ts-nocheck
/* eslint-disable */

"use client";

import { Accordion, AccordionItem, Button, Icon, RichText } from "tempdev-ds";

import DisplayImage from "@/components/common/DisplayImage/DisplayImage";
import { IArchiveGrid } from "@/lib/wordpress/_types/archiveGrid";
import Link from "next/link";
import React from "react";
import { styled } from "styled-components";
import { useGlobalContext } from "@/lib/globalContext";

const StyledAccordion = styled(AccordionItem)`
  & > span{
    color: var(--color-secondary90) !important;
  }
`;

export default function BlockServicesAcc({ attrs }: IArchiveGrid) {
  const { grid, className } = attrs || {};
  const state = useGlobalContext();

  if (!grid) return <></>;

  return (
    <Accordion className={attrs.className || ""}>
      {grid &&
        grid.length > 0 &&
        grid.map((cat: Record<string, unknown>, i: number) => {
          return (
            <StyledAccordion
              key={`service-acc-${i}`}
              title={cat?.name}
              imageElement={
                <DisplayImage
                  url={cat?.acf?.image as string}
                  alt={cat?.name ? (cat?.name as string) : ""}
                  width={48}
                  height={48}
                />
              }
              className="home-acc"
              style={{
                backgroundColor: cat?.acf?.color_soft,
                border: 0,
              }}
            >
              <RichText tag="p" className={"mt-3"}>{cat?.description}</RichText>
              <Link href={cat?.slug} legacyBehavior>
                <Button
                  variant="link"
                  href={cat?.slug}
                  rightIcon={
                    <Icon
                      icon="icon-arrow-link"
                      style={{ color: `${cat?.acf?.color_strong} !important` }}
                    />
                  }
                  style={{ color: cat?.acf?.color_strong }}
                  className="mt-5"
                >{`All ${cat.name} Consulting Services`}</Button>
              </Link>
            </StyledAccordion>
          );
        })}
    </Accordion>
  );
}
