import { BlocksProps } from '../_types/blocksStyle';
import { WpRequest } from '../wpRequest';
import { getCurrentDate } from '@/lib/helpers/dateFormate';

/**
 * Format and retrieve expanded block data.
 */
export default async function formatBlockData(blocks: BlocksProps, locale: string, searchParams: { q?: string }) {
  return await Promise.all(
    blocks.map(async (block: BlocksProps) => {
      const { attrs, blockName } = block
      let blockAttrs = attrs || {}

      if (blockName) {
        switch (blockName) {
          case 'miewtheme/search-page':
            blockAttrs = {
              ...blockAttrs,
              grid: await WpRequest({
                post_type: "search",
                variables: {
                  lang: locale,
                  limit: -1,
                  search: searchParams && searchParams?.q ? searchParams?.q : "",
                }
              })
            }

            break
          case 'core/block':
            const reusableBlock = await WpRequest({
              post_type: "wp_block",
              variables: {
                lang: locale,
                slug: attrs.ref
              }
            })

            block.innerBlocks = reusableBlock.blocks
            break

          case 'miewtheme/archive-grid':
            blockAttrs = {
              ...blockAttrs,
              grid: await WpRequest({
                post_type: blockAttrs?.post_type,
                variables: {
                  lang: locale,
                  category: blockAttrs?.cat || "",
                  limit: blockAttrs?.per_page || -1,
                  offset: blockAttrs?.offset || 0,
                  order: blockAttrs?.order || "",
                  orderby: blockAttrs?.orderby || "",
                  date: blockAttrs?.post_type === "events" ? getCurrentDate() : "",
                  dateBefore: blockAttrs?.post_type === "events" ? true : false,
                }
              })
            }

            break

          case 'miewtheme/authors-grid':
            blockAttrs = {
              ...blockAttrs,
              grid: await WpRequest({
                post_type: "authors",
                variables: {
                  lang: locale,
                  limit: blockAttrs?.per_page || -1,
                },
                isContent: false,
              })
            }

            break

          case 'miewtheme/testimonials-grid':
            blockAttrs = {
              ...blockAttrs,
              grid: await WpRequest({
                post_type: "testimonials",
                variables: {
                  lang: locale,
                  limit: blockAttrs?.per_page || -1,
                  category: blockAttrs?.cat || "",
                }
              })
            }
            break

          case 'miewtheme/team-grid':
            blockAttrs = {
              ...blockAttrs,
              grid: await WpRequest({
                post_type: "team",
                variables: {
                  lang: locale,
                  limit: blockAttrs?.per_page || -1,
                  orderby: 'menu_order',
                  order: "asc"
                }
              })
            }

            break
          case 'miewtheme/faqs-grid':
            blockAttrs = {
              ...blockAttrs,
              grid: await WpRequest({
                post_type: "faqs",
                variables: {
                  lang: locale,
                }
              })
            }

            break

          case 'miewtheme/logos-grid':

            blockAttrs = {
              ...blockAttrs,
              grid: await WpRequest({
                post_type: "logos",
                variables: {
                  lang: locale,
                  limit: blockAttrs?.per_page || -1,
                  category: blockAttrs?.cat || "",
                }
              })
            }

            break

          case 'miewtheme/products-grid':
            blockAttrs = {
              ...blockAttrs,
              grid: await WpRequest({
                post_type: "products",
                variables: {
                  lang: locale,
                  limit: blockAttrs?.per_page || -1,
                }
              })
            }
            break

          case 'miewtheme/services-grid':
            blockAttrs = {
              ...blockAttrs,
              grid: await WpRequest({
                post_type: "services",
                variables: {
                  lang: locale,
                  limit: blockAttrs?.per_page || -1,
                }
              })
            }
            break

          case 'miewtheme/services-acc':
            blockAttrs = {
              ...blockAttrs,
              grid: await WpRequest({
                post_type: "taxonomies",
                isContent: false,
                variables: {
                  category: 'services-category',
                  lang: locale,
                  limit: blockAttrs?.per_page || -1,
                  order: "asc"
                }
              })
            }
            break

          case 'miewtheme/glossary-grid':
            blockAttrs = {
              ...blockAttrs,
              grid: await WpRequest({
                post_type: "glossary",
                variables: {
                  lang: locale,
                  limit: -1,
                }
              })
            }
            break


          case "gravityforms/form":
            blockAttrs = {
              ...blockAttrs,
              formData: await WpRequest({
                post_type: `gf/v2/forms/${blockAttrs.formId}/`,
                isContent: false,
                isForms: true,
              }),
            }
            break;

          default:
            break
        }

        const innerBlocksFormatted = block.innerBlocks ? await formatBlockData(block.innerBlocks as unknown as BlocksProps, locale, searchParams) : []
        return { blockName, attrs: blockAttrs, innerBlocks: innerBlocksFormatted }
      }
    })
  )
}
