// @ts-nocheck
/* eslint-disable */

"use client";

import GravityForm from "@/components/common/GravityForm/GravityForm";

type IBlockGravityForms = {
  attrs: {
    formData?: {
      cssClass?: string;
      description?: string;
      fields?: object;
      formId?: number;
      title?: string;
    };
  };
};

export default function BlockGravityForm({ attrs }: IBlockGravityForms) {
  try {
    return <GravityForm {...attrs} />;
  } catch (ex) {
    console.log("GravityForm Block error", ex);
  }
}
